import * as React from "react"
import { Container, LargeText, Block, IconImg, Dot, ContactBtn } from "../styles/homeStyles"
import GoFundMeImg from "../images/goFundMe.png"
import PaypalImg from "../images/paypal.png"
import Modal from "./modal"

const Support = () => (
  <Container id="donate">
      <LargeText support>Support the Project</LargeText>
      <Block>
          <a href="https://www.gofundme.com/inland-arts-documentary-film" target="_blank" ><IconImg src={GoFundMeImg}/></a>
          <Modal btn={true} />
      </Block>
  </Container>
)

export default Support