import styled from 'styled-components'
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 100% 95%;
    margin-bottom: ${ props => props.banner ? '100px' : '0px' };
    
`;

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 80%;
    justify-content: center;
`;

const Event = styled.div`
    display: flex;
    flex-direction: ${ props => props.flip ? 'row-reverse' : 'row' };
    flex-wrap: no wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 200px;
`;

const EventImg = styled.img`
    margin: 0px;
    width: 49%;
    object-fit: contain;
`;

const EventInfo = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 49%;
    color: #202020;
    margin-top: 120px;
`;

const EventTitle = styled.div`
    font-size: 26px;
    text-align: left;
    width: 100%;
`;

const EventDate = styled.div`
    font-size: 18px;
    text-align: left;
    width: 100%;
`;

const EventPara = styled.div`
    font-size: 20px;
    text-align: left;
    width: 100%;
    line-height: 1.5;
    padding-top: 20px;
`;

const Goto = styled(Link)`
    font-size: 24px;
    border: solid thin #000;
    color: #202020;
    text-decoration: none;
    padding: 15px;
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'tk-linotype-didot-n7';
    :hover {
        cursor: pointer;
    }
`;



export {
    Container, 
    Wrapper, 
    Event, 
    EventImg, 
    EventInfo, 
    EventTitle, 
    EventDate, 
    EventPara,
    Goto,
}