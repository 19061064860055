import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container, LargeText, HeroImage } from "../styles/homeStyles"


const Hero = ({title, passedImage}) => (
  <Container>
    <LargeText>{title.split('\n').map( (it, i) => <div key={'x'+i}>{it}</div> )}</LargeText>
    <HeroImage src={passedImage}/>
  </Container>
)

export default Hero