import styled from 'styled-components'
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: ${ props => props.about ? '150px' : '50px' };
    margin-bottom: ${ props => props.about ? '150px' : '50px' };
    font-family: 'tk-linotype-didot-n7';
    @media (max-width: 769px) {
        padding-top: ${ props => props.about ? '125px' : '50px' };
        margin-bottom: ${ props => props.about ? '125px' : '50px' };
    }
    @media (max-width: 500px) {
        padding-top: ${ props => props.about ? '100px' : '50px' };
        margin-bottom: ${ props => props.about ? '100px' : '50px' };
    }
`;

const View = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: center;
height: 80vh;
font-family: 'tk-linotype-didot-n7';
align-content: center;
@media (max-width: 769px) {

}
@media (max-width: 500px) {

}
`;

const LargeText = styled.div`
    font-size: 126px;
    width: 100%;
    z-index: 2;
    line-height: 1;
    margin-bottom: ${ props => props.support ? '50px' : '-6%' };
    color: #202020;
    font-family: 'tk-linotype-didot-n7';
    @media (max-width: 860px) {
        font-size: 90px;
        margin-bottom: ${ props => props.support ? '50px' : '-8%' };

    }
    @media (max-width: 700px) {
        font-size: 75px;
        margin-bottom: ${ props => props.support ? '50px' : '-10%' };

    }
    @media (max-width: 550px) {
        font-size: 65px;
        margin-bottom: ${ props => props.support ? '50px' : '-10%' };

    }
    @media (max-width: 400px) {
        font-size: 50px;
        margin-bottom: ${ props => props.support ? '50px' : '-10%' };

    }
    
`;

const HeroImage = styled.img`
    margin: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
`;

const Title = styled.div`
    font-size: 68px;
    color: #202020;
    width: 100%;
    z-index: 2;
    align-self: center;
    margin-bottom: 20px;
    line-height: 1;
    @media (max-width: 768px) {
        font-size: 45px;
    }
`;

const Subtitle = styled.div`
display: flex;
    font-size: 28px;
    width: 100%;
    font-family: serif;
    text-align: center;
    justify-content: center;
    z-index: 2;
    margin-bottom: 40px;
    @media (max-width: 769px) {
        font-size: 16px;
        margin-top: 25px;
        text-align: center;
    }
    @media (max-width: 500px) {
        font-size: 14px;
        margin-top: 25px;
    }
`;

const Para = styled.div`
    font-size: 22px;
    width: 70%;
    text-align: left;
    line-height: 1.5;
    font-family: 'tk-futura-pt-n5';
    @media (max-width: 769px) {
        font-size: 18px;
        width: 75%;
        margin-right: ${ props => props.flip ? '0%' : '5%' };
        margin-left: ${ props => props.flip ? '5%%' : '0%' };
    }
    @media (max-width: 500px) {
        font-size: 16px;
        width: 75%;
    }
`;

const Block = styled.div`
    display: flex;
    flex-flow: row no wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin-top: 25px;
    @media (max-width: 500px) {
        width: 100%;
    }
`;

const IconImg = styled.img`
    margin: 0;
    :hover {
        cursor: pointer;
    }
`;

const Dot = styled.div`
    height: 15px;
    width: 15px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
`;

const Goto = styled(Link)`
    color: #202020;
    text-decoration: none;
    align-self: center;
    z-index: 2;
    width: 25%;
    text-align: right;
    :hover {
        text-decoration: none;
        color: #202020;
    }
`;

const Article= styled.div`
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    width: 32%;
    z-index: 2;
`;

const Words = styled.div`
    font-size: 80px;
    color: #20202010;
    width: 100%;
    z-index: 1;
`;

const Info = styled.div`
display: flex;
flex-flow: row wrap;
align-content: center;
justify-content: flex-end;
width: 100%;
`;

const Pic = styled.img`
    margin: 0;
`;

const ArticleBlock = styled.div`
display: flex;
flex-flow: row wrap;
align-content: center;
justify-content: space-between;
width: 100%;
height: 75%;
`;

const ArticleTitle = styled.div`
    display: flex;
    font-size: 18px;
    width: 100%;
    margin-bottom: -15px;
    font-family: serif;
    text-align: ${ props => props.flip ? 'right' : 'left' };
    z-index: 2;
`;

const BackgroundWords = styled.p`
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
`;



export {
    Container,
    LargeText,
    HeroImage,
    Subtitle,
    Para,
    Block,
    IconImg,
    Dot,
    View,
    Goto,
    Article,
    Words,
    Title,
    Info,
    Pic,
    ArticleBlock,
    ArticleTitle,
    BackgroundWords,
}