import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import About2 from "../components/about2"
import Support from "../components/support"
import HeroImg1 from "../images/image1.png"
import Banner from "../components/banner"

const IndexPage = () => (
  <Layout>
    <Seo 
    title="Home"
    keywords={["Spokane Arts", "Spokane Art Event", "Spokane Artist", "Art Documentary", "Documentary Film", "Art Film", "Spoakne Washington", "Inland Northwest", "Pacific Northwest", "PNW"]}
    discription="Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better."
    />
    <Hero title={"INLAND \n ARTS"} passedImage={HeroImg1}/>
    <About/>
    <Banner/>
    <Support/>
  </Layout>
)

export default IndexPage
