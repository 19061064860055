import * as React from "react"
import { View, Title, Para, Subtitle } from "../styles/homeStyles"

const About = () => (
  <View id="about">
    <Title>Who Are We?</Title>
    <Para>Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all.</Para>
  </View>
)

export default About