import * as React from "react"
import { Container, Wrapper, Goto } from "../styles/eventStyles"
import { Para, Title } from "../styles/homeStyles"
import PaintImg from "../images/brush-stroke-banner-6.png"

const Banner = () => (
  <Container banner style={{padding: "50px", backgroundImage: `url(${PaintImg})`}}>
      <Wrapper>
          <Title>Youth Contest</Title>
          <Para style={{width: "100%", textAlign: "center"}}>Check out our upcoming youth contest.</Para>
          <Goto to="/youth-contest">More Details</Goto>
      </Wrapper>
  </Container>
)

export default Banner